// Generated by Framer (ae1c51c)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ctCHnVhAR"];

const variantClassNames = {ctCHnVhAR: "framer-v-1qrzqqb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "ctCHnVhAR", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ctCHnVhAR", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-RgsA4", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1qrzqqb", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ctCHnVhAR"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-RgsA4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RgsA4 * { box-sizing: border-box; }", ".framer-RgsA4 .framer-11mq1mc { display: block; }", ".framer-RgsA4 .framer-1qrzqqb { height: 517px; position: relative; width: 553px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 517
 * @framerIntrinsicWidth 553
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerriVK96BLP: React.ComponentType<Props> = withCSS(Component, css, "framer-RgsA4") as typeof Component;
export default FramerriVK96BLP;

FramerriVK96BLP.displayName = "Blog filters";

FramerriVK96BLP.defaultProps = {height: 517, width: 553};

addFonts(FramerriVK96BLP, [])